import React, { useState } from 'react';
import img1 from '../src/Assetes/img1.jpg';

const Home = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [requirement, setRequirement] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        setError(null);
        setSuccess(false);

        const formData = {
            name,
            email,
            company,
            requirement
        }

        try {
            const response = await fetch('https://formspree.io/f/xldezwqd', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setSuccess(true);
                setName('');
                setEmail('');
                setCompany('');
                setRequirement('');
            } else {
                setError('There was an issue with your submission. Please try again later.');
            }
        } catch (error) {
            setError('There was an error with your request. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-h-screen flex flex-col">
            <nav className="fixed w-full top-0 px-5 z-50 bg-white md:px-10 lg:px-20 flex justify-between items-center shadow-md h-[65px]">
                <img src="/logo.png" className="h-[50px]" alt="" />
                <div onClick={() => {
                    window.location.href = "mailto:sales@frontlinetextiles.com"
                }} className="cursor-pointer hover:text-[#d5aa61]">Contact</div>
            </nav>

            <div className="grid lg:grid-cols-2 mt-[65px] gap-y-10 lg:gap-y-0 pb-5 lg:pb-0">
                {/* Left Section */}
                <div className="flex-1 relative h-[calc(100vh-65px)]">
                    <img src={img1} alt="Coming Soon" className="object-cover h-full w-full" />
                    <div className="Organum absolute top-4 left-5 md:left-10 lg:left-20 bg-black/40 text-white px-3 rounded-full backdrop-blur-xl"
                    >
                        <div>+91 7878251565 - Hiren Gangani</div>
                    </div>
                </div>

                {/* Right Section with Form */}
                <div className="flex flex-col justify-center items-center lg:bg-gray-50 px-6 py-5 h-[calc(100vh-65px)]">
                    <h2 className="text-2xl font-bold mb-6">Get in Touch</h2>
                    <form onSubmit={handleSubmit} className="w-full max-w-md space-y-4">
                        {/* Name Field */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 text-start mb-1">Name</label>
                            <input
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="w-full px-3 py-1 border rounded shadow-sm focus:outline-none"
                                placeholder="Enter your name"
                                required
                            />
                        </div>

                        {/* Email Field */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1 text-start">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full px-3 py-1 border rounded shadow-sm focus:outline-none"
                                placeholder="Enter your email"
                                required
                            />
                        </div>

                        {/* Company Name Field */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1 text-start">Company Name</label>
                            <input
                                type="text"
                                name="company"
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                className="w-full px-3 py-1 border rounded shadow-sm focus:outline-none"
                                placeholder="Enter your company name"
                                required
                            />
                        </div>

                        {/* Requirement Field */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1 text-start">Requirement</label>
                            <textarea
                                name="requirement"
                                value={requirement}
                                onChange={(e) => setRequirement(e.target.value)}
                                className="w-full px-3 py-1 border rounded shadow-sm focus:outline-none"
                                rows="4"
                                placeholder="Describe your requirements"
                                required
                            />
                        </div>

                        {/* Submit Button */}
                        <button
                            type="submit"
                            className="w-full py-1 px-4 shadow transition border border-black hover:bg-black hover:text-white"
                            disabled={loading}
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </form>

                    {/* Success and Error Messages */}
                    {success && <p className="text-green-600 mt-4">Thank you for your submission! We will get in touch with you soon.</p>}
                    {error && <p className="text-red-600 mt-4">{error}</p>}
                </div>
            </div>
        </div>
    );
};

export default Home;